var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("ak-form", {
    key: _vm.bundle + _vm.definition + _vm.objectId,
    attrs: {
      bundle: _vm.bundle,
      definition: _vm.definition,
      objectId: _vm.objectId,
      relationString: _vm.relationString,
    },
    on: { save: _vm.goToGrid },
    scopedSlots: _vm._u([
      {
        key: "submit-button",
        fn: function (ref) {
          var submit = ref.submit
          var form = ref.form
          return [
            _c(
              "div",
              { staticClass: "p-l-xs-20 p-r-xs-20 p-b-xs-20" },
              [
                _c(
                  "v-btn",
                  {
                    attrs: { color: "accent" },
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        return submit.apply(null, arguments)
                      },
                    },
                  },
                  [_vm._v("test")]
                ),
              ],
              1
            ),
          ]
        },
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }