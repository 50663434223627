<template>
        <ak-form
                :bundle="bundle"
                :definition="definition"
                :objectId="objectId"
                :relationString="relationString"
                @save="goToGrid"
                :key="bundle + definition + objectId"
        >

            <template v-slot:submit-button="{submit, form}">
                <div class="p-l-xs-20 p-r-xs-20 p-b-xs-20">
                    <v-btn color="accent" @click.prevent="submit">test</v-btn>
                </div>
            </template>
        </ak-form>
</template>

<script>

export default {
    name: 'FormPage',
    computed: {
        bundle() {
            return this.$route.params.bundle;
        },
        definition() {
            return this.$route.params.definition;
        },
        objectId() {
            return this.$route.params.objectId ?
                    this.$route.params.objectId:
                    'new';
        },
        relationString() {
            return this.$route.params.relationString;
        }
    },
    methods: {
        goToGrid(data) {
            this.$router.push({ name: 'grid' , params: {
                    bundle: data.bundleId,
                    gridDefinition: data.definitionId,
                }});
        }
    }
}
</script>
